import React, {useEffect, useState} from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import Faq from "../../components/faq/faq";
import HeaderLight from "../../components/header/headerLight/headerLight";
import SeeMore from "../../components/seeMore/seeMore";

import "./style.scss";
import LexiqueLetters from "../../components/lexiqueLetters";
import biFilter from "../../images/produit/bi_filter.svg";

const Lexique = ({ location, data }) => {
  const definitions = data.allStrapiDefinition.nodes;
  const articles = data.articles.nodes;

  const getLetterNumberMap = (def) => {
    const map = new Map();
    def.forEach((definition) => {
      const firstLetter = definition.Titre[0].toUpperCase();

      map.has(firstLetter)
        ? map.set(firstLetter, map.get(firstLetter) + 1)
        : map.set(firstLetter, 1);
    });

    return map;
  };

  const letterNumberMap = getLetterNumberMap(definitions);
  const letterNumberArray = Array.from(letterNumberMap);

  const currentLetterInit = letterNumberArray[0][0];
  let searchDefinitionInit;

  const [searchedDefinition, setSearchedDefinition] = useState(searchDefinitionInit);
  const [currentLetter, setCurrentLetter] = useState(currentLetterInit);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const definitionId = params.get("id");
    if (definitionId) {
      const definitionFound = definitions?.find(definition => definition?.strapi_id === +definitionId)
      if (definitionFound) {
        setSearchedDefinition(definitionFound);
        setCurrentLetter(definitionFound?.Titre[0]);
      }
    }
  }, []);
  
  return (
    <>
      <Helmet>
        <body className="lexique" />
      </Helmet>
      <Layout>
        <SEO title="Lexique" />
        <HeaderLight
          title={
            <>
              <strong>Le lexique de </strong> l'assurance du BTP
            </>
          }
          location={location}
          crumbLabel="Lexique"
        />

        <div className="lexique-letters-mobile">
          <label onClick={() => setIsFilterOpen((prev) => !prev)}>
            <img src={biFilter} alt="filtres mobile button" />
            Filtrer
          </label>
          {isFilterOpen &&
            <LexiqueLetters
            currentLetter={currentLetter}
            letterNumberArray={letterNumberArray}
            setSearchedDefinition={(val) => setSearchedDefinition(val)}
            setCurrentLetter={(val) => setCurrentLetter(val)}
          />}
        </div>

        <div className="lexique-letters">
          <LexiqueLetters
            currentLetter={currentLetter}
            letterNumberArray={letterNumberArray}
            setSearchedDefinition={(val) => setSearchedDefinition(val)}
            setCurrentLetter={(val) => setCurrentLetter(val)}
          />
        </div>

        <section className="lexique__definitions">
        { searchedDefinition
          ? (
            <>
            <Faq question={searchedDefinition.Titre} number={searchedDefinition.id} isChecked={true}>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                    children={searchedDefinition.Contenu.data.Contenu} 
                  />
                </Faq>
            </>
            ) 
          : (
            <>
            {definitions
            .filter(
              (definition) =>
                definition.Titre[0].toUpperCase() === currentLetter
            )
            .map((definition, i) => {
              return (
                <Faq question={definition.Titre} number={definition.id} key={i}>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                    children={definition.Contenu.data.Contenu} 
                  />
                </Faq>
              );
            })}
            </>
            )
        }
          
        </section>
        <SeeMore articles={articles} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query LexiquePageQuery {
    allStrapiDefinition(sort: { fields: Titre }) {
      nodes {
        id
        strapi_id
        Titre
        Contenu {
          data {
            Contenu
          }
        }
      }
    }

    articles: allStrapiArticle(
      sort: { fields: [Important, Date_De_Publication], order: [DESC, DESC] }
    ) {
      nodes {
        Important
        Titre
        Slug
        Date_De_Publication
        Image_Vignette {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`;

export default Lexique;
