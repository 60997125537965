import React from "react";

import "./index.scss";

export default function LexiqueLetters({currentLetter = "A", letterNumberArray, setCurrentLetter, setSearchedDefinition}) {
  return (
    <div className="lexique__select">
      {letterNumberArray.map((letterNumber, i) => {
        return (
          <div
            className={`select__input ${currentLetter === letterNumber[0] ? "select__input__selected" : ""}`}
            key={i}
            onClick={() => {
              setCurrentLetter(letterNumber[0]);
              setSearchedDefinition(undefined);
              const clist = document.getElementsByTagName("input");
              for (const item of clist) {
                item.checked = false;
              }
            }}
          >
            <span className="select__letter">{letterNumber[0]}</span>
            <span className="select__number">({letterNumber[1]})</span>
          </div>
        );
      })}
    </div>
  )
}
